<script lang="ts" setup>
interface Props {
  version?: 'success' | 'error' | 'warning' | 'default'
}

const props = withDefaults(defineProps<Props>(), {
  version: 'default'
})
</script>

<template>
  <span :class="['badge', `badge-${props.version}`]">
    <slot />
  </span>
</template>

<style lang="postcss" scoped>
.badge {
  background-color: var(--color-slate-200);
  color: var(--color-slate-700);
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.badge-success {
  background-color: var(--color-green-200);
  color: var(--color-green-700);
}

.badge-error {
  background-color: var(--color-rose-200);
  color: var(--color-rose-700);
}

.badge-warning {
  background-color: var(--mp-color-orange-200);
  color: var(--mp-color-orange-700);
}

.badge-default {
  background-color: var(--mp-color-slate-200);
  color: var(--mp-color-slate-700);
}
</style>
